<template>
  <v-card style='padding: 10px;'>
    <v-card-title>
      <div class='title font-weight-medium'>Summary</div>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-xs fluid>
        <v-layout row wrap>
          <v-flex sm6 md6>
            <div class='text-xs-left'>
              <span class='text_bold'>{{ patient.Name }}</span>
            </div>
          </v-flex>
          <v-flex sm6 md6>
            <div class='text-xs-right' style='margin-right: 1in;'>
              <span class='text_bold'>{{ patient.PatientId }}</span>
            </div>
          </v-flex>
          <v-flex sm12 md12>
            <div class='text-xs-left'>
              <span class='text_bold'>Mobile:&nbsp;</span>
              <span class='text_bold font-weight-regular'>{{
                patient.Mobile
              }}</span>
            </div>
          </v-flex>
          <v-flex
            xs12
            sm12
            md12
            lg12
            v-for='(problem, index) in problems.Problems.Object'
            :key='index'
          >
            <div class='text-xs-left'>
              <span class='text_bold'>{{ problem.Name }}:&nbsp;</span>
              <span class='text_bold font-weight-regular'>{{
                ArrayToString(problem.Choices)
              }}</span>
              <span class='text_bold font-weight-regular'
                >&nbsp;-&nbsp;{{ problem.Others }}
              </span>
              <span class='text_bold font-weight-regular'
                >&nbsp;-&nbsp;{{ problem.Since }}
              </span>
              <span class='text_bold font-weight-regular'>
                &nbsp;-&nbsp;{{ getPain(problem.Scale) }}
              </span>
            </div>
          </v-flex>
          <v-flex sm12 md12 lg12>
            <div class='text-xs-left'>
              <span class='text_bold'>Examination:&nbsp;</span>
              <span class='text_bold font-weight-regular'
                >Heigth - {{ exam.Height }} cms, Weight -
                {{ exam.Weight }} Kgs</span
              >
            </div>
          </v-flex>
          <v-flex sm12 md12 lg12>
            <div class='text-xs-left'>
              <span class='text_bold'>Diagnosis:&nbsp;</span>
              <span class='text_bold font-weight-regular'>{{
                ArrayToString(diagnoses.JDiagnosis)
              }}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { isEmpty } from "lodash";

export default {
  props: {
    fixedHeight: {
      type: Boolean,
      default: true
    },
    patient: {
      type: Object,
      default: () => {
        return {};
      }
    },
    problems: {
      type: Object,
      default: () => {
        return { Problems: [] };
      }
    },
    moreProblems: {
      type: Object,
      default: () => {
        return {
          Treatments: {},
          Habits: {},
          Others: {},
          Family: {},
          Children: {}
        };
      }
    },
    exam: {
      type: Object,
      default: () => {
        return {};
      }
    },
    invest: {
      type: Object,
      default: () => {
        return {};
      }
    },
    diagnoses: {
      type: Object,
      default: () => {
        return {
          JDiagnosis: []
        };
      }
    },
    spt: {
      type: Object,
      default: () => {
        return {
          Control: { Erythema: '', Wheal: '' },
          Positive: { Erythema: '', Wheal: '' },
          Allergens: []
        };
      }
    },
    ige: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      painTypes: [
        'No pain',
        'Very mild',
        'Discomforting',
        'Tolerable',
        'Distressing',
        'Very Distressing',
        'Intense',
        'Very Intense',
        'Utterly horrible',
        'Excruciating unbearable',
        'Unimaginable unspeakable'
      ]
    };
  },
  methods: {
    /**
     * @return {string}
     */
    ArrayToString(items) {
      let str = '';
      if (isEmpty(items)) return str;
      for (let i = 0; i < items.length; i++) str += items[i] + ', ';
      return str.substring(0, str.length - 2);
    },
    /**
     * @return {string}
     */
    getPain(scale) {
      return this.painTypes[scale - 1];
    }
  }
};
</script>

<style scoped />
