import WebH from "./web";

const prescriptionApi = {
  async getAppointment(pid) {
    return await WebH.Get1("/apptmnts/spid?pid=" + pid);
  },
  async getAllergy(aid) {
    let url = "/allergies/appt/" + aid;
    const res = await WebH.Get(url, "allergies");
    if (res.Success) {
      return res.Data;
    }
    return null;
  },
  async getHabit(aid) {
    let url = "/habits/appt/" + aid;
    const res = await WebH.Get(url, "habits");
    if (res.Success) {
      return res.Data;
    }
    return null;
  },
  async getExams(aid) {
    let url = "/preexams/appt/" + aid;
    const res = await WebH.Get(url, "pre examinations");
    if (res.Success) {
      if (res.Data.Id) {
        return res.Data;
      }
    }
    return null;
  },
  async getLast3(id) {
    const resp = await WebH.Get(
      "/prescriptions/search?pid=" + id,
      "last 3 prescriptions"
    );
    if (resp.Success) {
      return resp.Data;
    }
    return [];
  },
  async createAsync(data) {
    return await WebH.Post("/prescriptions", data);
  }
};

export default prescriptionApi;
