<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='850px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>View Prescription</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div id='printDoc'>
          <v-container>
            <v-row no-gutters>
              <v-col sm="12">
                <div style='margin-top: 1.5in;'></div>
              </v-col>
              <v-col sm="12">
                <div
                  class='text_bold text-sm-end'
                  style='margin-right: 1in;'
                >
                  {{ Date().toString() | FormatDateISO }}
                </div>
                <br />
              </v-col>
              <v-col sm="6">
                <div class='text-sm-end text_bold'>
                  {{ patient.Name }}
                </div>
              </v-col>
              <v-col sm="6">
                <div class='text-sm-end' style='margin-right: 1in;'>
                  <span class='text_bold'>ID:&nbsp;</span>
                  <span class='text_bold font-weight-regular'>{{
                    patient.PatientId
                  }}</span>
                </div>
              </v-col>
              <v-col sm="12">
                <br />
                <div>
                  <span class='text_bold'>Diagnosis: &nbsp;</span>
                  <span>{{ diagnosis }}</span>
                </div>
                <br />
              </v-col>
              <v-col sm="12">
                <div class='text_bold'>
                  Rx
                </div>
                <br />
              </v-col>
              <v-col sm="12">
                <ul>
                  <li v-for='(drug, index) in selectedDrugs' :key='index'>
                    <p class='pCap' style='margin-bottom: 2px;margin-top: 8px'>
                      {{ drug.Ingredients }} (&nbsp;<u>{{ drug.TradeName }}</u>&nbsp;)
                      - {{ drug.Quantity }}
                    </p>
                    <p class='pSmall' style='margin-bottom: 5px'>
                      {{ drug.Dosage }}
                    </p>
                  </li>
                </ul>
              </v-col>
              <v-col sm="12">
                <div class='text_bold text-xs-center'>
                  <br />
                  <br />
                  Review Before : {{ reviewAfter }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click='printDocument' color='primary'>Print</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click='closeDlg'>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Print from '/public/print';

export default {
  props: {
    dlgShow: { type: Boolean, default: false },
    patient: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewAfter: {
      type: String
    },
    selectedDrugs: {
      type: Array
    },
    diagnoses: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    diagnosis: {
      get() {
        try {
          return this.diagnoses.JDiagnosis.toString();
        } catch {
          return ['', ''].toString();
        }
      }
    }
  },
  methods: {
    // async getDiagnoses() {
    //   try {
    //     return this.diagnoses.JDiagnosis.toString();
    //   } catch {
    //     return ['', ''].toString();
    //   }
    //   // if (Object.keys(this.patient).length === 0) return [''];
    //   // if (this.patient) {
    //   //   if (this.diagnoses) {
    //   //     if (this.diagnoses.JDiagnosis) {
    //   //       console.log(this.diagnoses.JDiagnosis.toString());
    //   //       return this.diagnoses.JDiagnosis.toString();
    //   //     }
    //   //   }
    //   //   await this.$notifyDlg.show(
    //   //     'Diagnosis details are missing',
    //   //     'Error',
    //   //     'Error'
    //   //   );
    //   // }
    //   // return [''];
    // },
    closeDlg() {
      this.$emit('close');
    },
    printDocument() {
      Print({
        printable: 'printDoc',
        type: 'html',
        targetStyles: ['*']
      });
      //this.$htmlToPaper('printDoc');
    }
  }
};
</script>

<style scoped></style>
