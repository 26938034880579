import WebH from "./web";

const drugsPrescriptionApi = {
  async get(drugId) {
    const resp = await WebH.Get("/DrugPrescription/" + drugId, "Medicines");
    if (resp.Success) {
      return resp.Data;
    }
    return null;
  },

  async add(data) {
    return await WebH.Post("/DrugPrescription", data);
  },

  async update(data) {
    return await WebH.Put("/DrugPrescription/" + data.Id, data);
  },
  async delete(id) {
    return await WebH.Delete("/DrugPrescription/" + id);
  }
};

export default drugsPrescriptionApi;
