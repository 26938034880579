<template>
  <v-card class='elevation-0'>
    <v-card-title>
      <div class='title'>
        Prescription:
      </div>
    </v-card-title>
    <v-divider />
    <br />
    <v-card-text>
      <v-combobox label='Review after:' :items='months' v-model='reviewAfter' />
      <v-combobox
        label='Medicine:'
        :items='drugs'
        v-model='drug'
        item-text='Name'
        item-value='Id'
        clearable
        @change='api_getDrugPrescriptions'
      />
      <v-text-field
        v-model.trim='medicine.Dosage'
        label='Dosage:'
        counter='100'
        maxlength='100'
        @click='showDrugPrescriptionDropDown'
      />
      <v-menu
        v-model='showDrugPrescription'
        absolute
        offset-x
        :position-x='drugMenuPosX'
        :position-y='drugMenuPosY'
        nudge-bottom='20'
      >
        <v-list>
          <v-list-item
            v-for='item in drugPrescriptions'
            :key='item.Id'
            @click='loadMedicinePrescription(item)'
          >
            <v-list-item-title>{{ item.Dosage }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn color='primary' @click='addDrug'>Add Medicine</v-btn>
      <v-dialog v-model='editDialog' width='500px'>
        <v-card>
          <v-card-title>Edit Dosage</v-card-title>
          <v-card-text>
            <v-text-field v-model='editableDosage' />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click='closeEditDlg'>Close</v-btn>
            <v-btn color='primary darken-1' text @click='saveEditDlg'>Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        style='border: 1px solid grey;margin-top: 10px;height: 400px;overflow: auto'
      >
        <v-data-table
          style='height: 400px'
          :headers='headers'
          :items='selectedDrugs'
          hide-default-footer
          class='elevation-1'
        >
          <template v-slot:item.TradeName="{ item }">
            {{ item.TradeName }} [{{ item.Ingredients }}]
          </template>
          <template v-slot:item.actions="{ item }">
            <tr>
              <td style='text-align: center'>
                <button
                    class='linkBtn'
                    type='button'
                    @click='editDrug(item)'
                >
                  Edit
                </button>
                <button
                    class='linkBtn'
                    type='button'
                    @click='removeDrug(item)'
                >
                  Remove
                </button>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <br />
      <ViewPrescription
        :patient='patient'
        :diagnoses='diagnoses'
        :dlgShow='dlgShow'
        :selected-drugs='selectedDrugs'
        :review-after='reviewAfter'
        @close='closeDlg'
      />
      <br />
    </v-card-text>
    <v-card-actions>
      <v-btn color='primary' @click='saveDataAsync'>
        <v-icon class='iconText'>fa fa-save</v-icon>
        Save
      </v-btn>
      <v-btn @click='viewDlg'>
        <v-icon class='iconText'>fa fa-print</v-icon>
        Print
      </v-btn>

      <v-btn @click='resetPrescription'>
        <v-icon class='iconText'>fa fa-redo</v-icon>
        Reset
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import prescriptionApi from '../../api/prescription';
import drugsApi from '../../api/drugsPrescriptionApi';
import WebH from '../../api/web';
import ViewPrescription from '../../components/prescirptions/ViewPrescription';
import { isEmpty } from 'lodash'

export default {
  components: {
    ViewPrescription
  },
  props: {
    patient: {
      type: Object,
      default: () => {
        return null;
      }
    },
    diagnoses: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    drugs() {
      return this.$store.getters['DrugsStore/Drugs'];
    }
  },
  data() {
    return {
      editDialog: false,
      editableDosage: '',
      editableItemIndex: -1,
      showDrugPrescription: false,
      drugMenuPosX: 0,
      drugMenuPosY: 0,
      drugPrescriptions: [],
      drugPrescription: null,
      loading: false,
      months: ['1 month', '2 months', '6 months'],
      drug: null,
      dlgShow: false,
      medicine: {
        DrugId: 0,
        DrugPrescriptionId: 0,
        TradeName: '',
        Ingredients: '',
        Strength: '',
        Dosage: '',
        Quantity: '',
        Form: ''
      },
      reviewAfter: '',
      selectedDrugs: [],
      headers: [
        {
          text: 'Name & Composition',
          value: 'TradeName',
          sortable: true,
          width: '25%'
        },
        { text: 'Dosage', value: 'Dosage', sortable: false, width: '40%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '15%' }
      ]
    };
  },
  watch: {
    patient: function() {
      this.getPrescription();
    }
  },
  methods: {
    showDrugPrescriptionDropDown(e) {
      e.preventDefault();
      this.showDrugPrescription = false;
      this.drugMenuPosX = e.clientX;
      this.drugMenuPosY = e.clientY;
      this.$nextTick(() => {
        this.showDrugPrescription = true;
      });
    },
    loadMedicinePrescription(item) {
      this.drugPrescription = item;
      this.medicine.Dosage = this.drugPrescription.Dosage;
    },
    closeDlg() {
      this.dlgShow = false;
    },
    viewDlg() {
      this.dlgShow = true;
    },
    closeEditDlg(){
      this.editableDosage = '';
      this.editableItemIndex = -1;
      this.editDialog = false;
    },
    saveEditDlg() {
      if (this.editableItemIndex < 0) {
        this.closeEditDlg();
        return;
      }

      this.selectedDrugs[this.editableItemIndex].Dosage = this.editableDosage;
      this.closeEditDlg();
    },
    async addDrug() {
      if (this.medicine.Dosage === null || this.medicine.Dosage === '') {
        await this.$notifyDlg.show('Dosage is missing', 'Info', 'info');
        return;
      }
      this.medicine.DrugId = this.drug.Id;
      this.medicine.DrugPrescriptionId = this.drugPrescription.Id;
      this.medicine.TradeName = this.drug.Name;
      this.medicine.Ingredients = this.drug.Composition;
      //this.medicine.Dosage = this.drugPrescription.Dosage;
      this.medicine.Strength = this.drugPrescription.Strength;
      this.medicine.Quantity = this.drugPrescription.Quantity;
      this.medicine.Form = this.drugPrescription.Form;
      this.selectedDrugs.push(this.medicine);
      this.drug = null;
      this.drugPrescription = null;
      this.drugPrescriptions = [];
      this.medicine = {
        DrugId: 0,
        DrugPrescriptionId: 0,
        TradeName: '',
        Ingredients: '',
        Strength: '',
        Dosage: '',
        Quantity: '',
        Form: ''
      };
    },
    removeDrug(item) {
      const index = this.selectedDrugs.indexOf(item);
      if (index >= 0) {
        this.selectedDrugs.splice(index, 1);
      }
    },
    editDrug(item) {
      const index = this.selectedDrugs.indexOf(item);
      if (index >= 0) {
        this.editableDosage = this.selectedDrugs[index].Dosage;
        this.editableItemIndex = index;
        this.editDialog = true;
      }
    },
    resetPrescription() {
      this.drug = null;
      this.reviewAfter = '';
      this.medicine = {
        DrugId: 0,
        DrugPrescriptionId: 0,
        TradeName: '',
        Ingredients: '',
        Strength: '',
        Dosage: '',
        Quantity: '',
        Form: ''
      };
      this.selectedDrugs = [];
    },
    async saveDataAsync() {
      if (this.reviewAfter === null || this.diagnosis === '') {
        await this.$notifyDlg.show(
          'Please select the review after for the patient',
          'Info',
          'info'
        );
        return;
      }
      if (this.selectedDrugs.length <= 0) {
        await this.$notifyDlg.show(
          'Please enter the medicines for the patient',
          'Info',
          'info'
        );
        return;
      }
      if (this.patient.Id < 1) {
        await this.$notifyDlg.show('Invalid patient details', 'Info', 'info');
        return;
      }
      const data = {
        ReviewAfter: this.reviewAfter,
        Medicines: [],
        PId: this.patient.Id
      };
      for (let i = 0; i < this.selectedDrugs.length; i++) {
        const medicine = {
          DrugId: this.selectedDrugs[i].DrugId,
          DrugPrescriptionId: this.selectedDrugs[i].DrugPrescriptionId,
          Ingredients: this.selectedDrugs[i].Ingredients,
          TradeName: this.selectedDrugs[i].TradeName,
          Strength: this.selectedDrugs[i].Strength,
          Dosage: this.selectedDrugs[i].Dosage,
          Form: this.selectedDrugs[i].Form,
          Quantity: this.selectedDrugs[i].Quantity
        };
        data.Medicines.push(medicine);
      }
      const loader = this.$loading.show();
      const resp = await prescriptionApi.createAsync(data);
      loader.hide();
      if (resp.Success) {
        await this.$notifyDlg.show('Prescription saved', 'Info', 'info');
      } else {
        await this.$notifyDlg.show(resp.Data, 'error', 'error');
      }
    },
    async api_getDrugPrescriptions() {
      let id = 0;
      if (this.drug) id = this.drug.Id;
      if (id === 0) return;
      const res = await drugsApi.get(id);
      if (res == null) {
        await this.$notifyDlg.show(
          'unable to retrieve medicine prescriptions',
          'Error',
          'error'
        );
      } else {
        this.drugPrescriptions = res;
      }
    },
    async getPrescription() {
      if (isEmpty(this.patient) || this.patient.Id < 1) return;
      this.loading = true;
      this.selectedDrugs = [];
      let url = '/prescriptions/' + this.patient.Id + '/latest';
      const res = await WebH.Get(url, 'latest prescription');
      if (res.Success) {
        if (res.Data === '') this.selectedDrugs = [];
        else {
          const presc = res.Data;
          this.reviewAfter = presc.ReviewAfter;
          for (let i = 0; i < presc.Medicines.Object.length; i++) {
            const drugId = presc.Medicines.Object[i].DrugId;
            const drug = this.drugs.find(x => x.Id === drugId); //this.$store.getters['DrugsStore/Drug'](drugId);
            if (drug === null) return;
            this.selectedDrugs.push({
              DrugId: drugId,
              DrugPrescriptionId: presc.Medicines.Object[i].DrugPrescriptionId,
              TradeName: drug.Name,
              Ingredients: drug.Composition,
              Strength: presc.Medicines.Object[i].Strength,
              Dosage: presc.Medicines.Object[i].Dosage,
              Form: presc.Medicines.Object[i].Form,
              Quantity: presc.Medicines.Object[i].Quantity
            });
          }
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped />
